/* src/App.css */

body {
  font-family: 'Helvetica Neue';
  margin: 0;
  padding: 0;


}


.contact-info h2 {
  color: black;
}

.contact-info p {
  color: black;
}

.contact {
  padding: 50px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact h2 {
  margin-top: 0;
  color: aquamarine;
}

.parallax {
   position: relative;
}

.parallax-contact-section {
  position: relative;
  background-image: url('../assets/contact_us.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-color: black
}

.parallax-content {

  position: relative;
  z-index: 1; /* Ensure content is above the background */
  text-align: left;
  font-weight: bold;
  font-size: large;
  padding: 100px;
  color: black;/* Set the text color for readability */
  backdrop-filter: blur(8px);
  transition: all 0.5s;
  font-family:  Verdana,sans-serif, 
  
}

.parallax-content:hover {
  transition: all 0.5s;
}

/* Add more styles as needed */


.contact-row {
  display: flex;
  background-color: black;
}

.contact-row h1 {
  color: aquamarine;
}


.contact-row h5 {
  color: aquamarine;
}

.about-contact-info {
  padding-left: 10%;
}

.about-contact-info h6 {
  color: aquamarine;
}

#footers {
  padding: 3% 2%;
  font-weight:100;
  }

  #footers i {
   padding: 0 1% 1%;
  }

  #footers p {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }



