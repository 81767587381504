.contact-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: black;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(14, 13, 13, 0.2);
  }

  .form-decor{
    text-align: start;
    color: aqua;
   
  }
  
  h2 {
    text-align: start;
  }
  
  .alert {
    margin-top: 10px;
  }

  .button-decor {
    margin-top: auto;
    background-color: black !important;
    text-decoration-color:  aqua !important;
  }


  .contactPage {
    align-items: center;
    padding: 10%;
    background-color: rgb(22, 221, 221);
  }

  .contact-form h2 {
    color: rgb(127, 229, 255) ;
  }

  .contact-form:hover {
    transform: translateY(-5px);
  }