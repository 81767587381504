body {
    background-color: #06aeac;
}

.navbar {
  padding:  0 0 4.5rem;
  background-color: black;

 }

 .navbar-brand {
  font-family: Verdana, sans-serif;
  font-size : 3rem;
  padding: 5%;
 }

 .nav-item  {
   padding : 0 18px;
   font-family: Verdana, sans-serif;
 }
.hero {
    position: relative;
    height: 100vh;
    text-align: start;
    color: aquamarine;
    font-family: Verdana, sans-serif;
  }

  .hero-button {
    background-color: black !important;
  }
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: start;
    
  }

  .hero-content h1 {
    text-align: start;
  }

  .hero-content p {
    text-align: start;
  }



  
  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    color: aquamarine;
  }
  
  p {
    font-size: 1.5rem;
    margin-bottom: 40px;
    
  }
  
  button {
    padding: 15px 30px;
    font-size: 1.2rem;
    background-color: #0cece8;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #06aeac;
  }

  @media screen and (max-width:1100px) {
   
    .hero-content p {
      font-size: 100%;
    }

    .hero-content h1 {
      font-size: 100%;
      font-weight: bolder;
    }

    .hero-content {
      padding: 15%;
      position: relative;
    }
  }


  