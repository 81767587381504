/* BlogPage.css */



.text-content h2 {
  color: aquamarine;
  text-align: left;
}

.values-card {
  padding: 2%;
}

.blog-container h3  {
  color: aquamarine;
  text-align: start;
}

.blog-container h1 {
  color: aquamarine;
}

.blog-container h2 {
  color: aquamarine;
}

p {
  color: aquamarine;
}


.contact-row {
  display: flex;
}


.contact-row h5 {
  color: aquamarine;
}



.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #01030C;
    font-family:  Helvetica, sans-serif;
  }
  
  .blog-post {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    background-color: #01030C;
  }
  
  .text-content {
    flex: 3;
    padding: 20px;
    background-color:#01030C;
    text-align: start;
    font-family:  Helvetica, sans-serif;
  
  }
  
  .image-content {
    flex: 1;
    padding: 20px;
    float: left;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }

  .scroll-animation-container {
    height: 200vh; /* Make sure there's enough scrollable content */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .scroll-animation {
    transition: transform 0.5s ease;
  }
  
  .scale-up {
    transform: scale(1);
  }
  
  .scale-down {
    transform: scale(0.8);
  }

  .round-image {
    border-radius: 30%;
  }

  .logo-image {
    border-radius: 50%;
    width: 50%;
    height: 50%;
    padding: 10%;
    position: relative;
    padding-left: 20%;
  }

  .blog-post:nth-child(even) .image-content {
    order: 2;
  }
  
  .blog-post:nth-child(even) .text-content {
    order: 1;
  }

  .contact-text-section {
    z-index: 1; /* Ensure content is above the background */
    text-align: left;
    font-weight: bold;
    padding-right: 10%;
    display: flex;
    flex-direction: row;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .contact-text-section p {
    padding: 5%;
  }

  .contact-about-info {
    align-items: center;
    text-align: left;
    flex-direction: row;
    display: flex;
    position: relative;
   
  }

  .about-contact-info {
    padding-left: 10%;
  }

  .about-contact-info h6 {
    color: aquamarine;
  }

  



  .choose h4{
       color: aquamarine;
       font-family: Verdana, Geneva, Tahoma, sans-serif;
  }


  #footers {
    padding: 3% 2%;
    font-weight:100;
    }
 
    #footers i {
     padding: 0 1% 1%;
    }
 

 


  @media screen and (max-width:700px) {
      .image-content {
        float: right;
      }

      img {
        width: 75%;
        height: auto;
      }
      .text-content {
         float: left;
      }

      .blog-container
      .blog-post {
        display: inline-block;
      }

      .contact-text-section p{
        font-size: medium;
        padding: 0%;
      }

      .contact-text-section {
        display: flex;
        flex-direction: row;
      }

      .logo-image {
        padding-right: 5%;
      }
      .about-contact-info {
        padding-top: 20%;
      }
  }
  
  
  /* Style headers, paragraphs, etc. to fit your design */
  