.services-section {
    background-color: #044756;
    padding: 80px 0;
    text-align: center;
    background-image: url('../assets/hero_two.png');
    font-family: Verdana,  sans-serif;
  }
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
    font-family: Verdana,  sans-serif;
  }
  
  .service-card {
    width: 300px;
    background-color: #100f0f;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
  }
  
  .service-card figure {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
  }
  
  .service-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .service-info {
    padding: 20px;
  }

  .services-section h1 {
    color: aquamarine;
    font: 2rem;
  }
  
  .service-info h3 {
    font-size: 24px;
    margin: 0;
    font-family: 'OverlockBold';
    color: aqua;
  }
  
  .service-info p {
    margin: 15px 0;
    color: aqua;
  }

  button {
    margin-top: 2rem;
    padding: 15px 30px;
    font-size: 1.2rem;
    font-family: 'OverlockBold';
    background-color: #0cece8;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  