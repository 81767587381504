/* LogoList.css */
@media only screen and (max-width: 600px) {

}


.logo-list-container {
  overflow: hidden;
  position: relative;
  align-items: center;
  background-color: black;
  width: 100%;
  height: 100%; /* Set the height according to your requirements */
  font-family: Verdana,  sans-serif;
}


.logo-list-container h1 {
  color: aquamarine;
  padding: 2%;
}

.logo-list {
  
  display: flex;
  animation: scroll 20s linear infinite; 
  /* Adjust the animation duration as needed */
}

.logo {
  margin-right: 20px; /* Adjust the spacing between logos */
  margin-top: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.logo img {
  max-width: 100%;
  max-height: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


@media screen and (max-width: 600px) {
  .logo-list-container h1 {
    font-size: xx-small;
    padding-top: 10%;
    height: 50%;
    display: inline;
    visibility: hidden;
  }
  .logo img {
      max-width: 50%; /* Adjust the maximum logo width for smaller screens */
      margin-right: 10px; /* Adjust the spacing between logos for smaller screens */
      max-height: 50%;
  }

  .logo-list {
     visibility: hidden;
  }
}